import { loadTossPayments } from '@tosspayments/payment-sdk';
import { toast } from 'react-hot-toast';
import { ITossPaymentsPaymentInfo, TTossPaymentsPaymentMethod } from '../@types/tossPayments';
  
  interface ITossPayParams extends ITossPaymentsPaymentInfo {
    paymentMethod: TTossPaymentsPaymentMethod
    userId: number
    onPaymentSuccess?: () => void
  }
  
  export const TossPay = async (params: ITossPayParams) => {
    // const clientKey = process.env.NEXT_PUBLIC_TOSS_PAYMENTS_CLIENT_KEY_DEV || '';
    const clientKey = process.env.NEXT_PUBLIC_TOSS_PAYMENTS_CLIENT_KEY || '';
  
    try {
      const tossPayments = await loadTossPayments(clientKey);
      const response = await tossPayments.requestPayment(params.paymentMethod, {
        amount: params.amount,
        orderId: params.orderId,
        orderName: params.orderName,
        customerName: params.userId?.toString(),
        successUrl: params.successUrl,
        failUrl: params.failUrl,
        easyPay: params?.easyPay,
        flowMode: params.flowMode,
      });
      console.log(response);
    } catch (e: any) {
      if (e?.message) {
        toast.error(`${e.message}`);
      }
    }
  };