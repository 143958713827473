import { Container } from '@/components/Container'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { Button } from '@nextui-org/react'
import { createOrder } from '@/apis/order'
import { useUserStore } from '@/stores/user'
import { useQuery } from 'react-query'
import { fetchConcept } from '@/apis/concepts/index'
import { Concept } from '@/apis/concepts/index.d'
import { makeLog } from '@/utils/fpixel'
import { TossPay } from '@/utils/payments'
import { PurchaseFailBottomsheet } from '@/components/bottomsheets/PurchaseFailBottomsheet'
import { toast } from 'react-hot-toast'
import * as Sentry from '@sentry/nextjs'
import { errorLog } from '@/utils/errorLogConstants'
import { countryMap } from '@/utils/countryMap'

export type PackagePageProps = {}

// 이거는 payment 실패 랜딩으로서 의미있음
export const PackagePage = ({}: PackagePageProps) => {
  const router = useRouter()
  const { conceptId, code, from_page } = router.query
  const [scrollIndex, setScrollIndex] = useState<number>(0)
  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false)
  const [dismissable, setDismissable] = useState<boolean>(false)
  const { userId, uuid, countryCode } = useUserStore()

  // TODO countryCode 항상 있는지 확인
  const conceptQuery = useQuery<Concept>(
    ['conceptResult', conceptId],
    async () => {
      return await fetchConcept(conceptId as string, countryCode!)
    },
    { enabled: conceptId !== undefined && !!countryCode, retry: false, retryDelay: 3000 },
  )

  const retryHandler = () => {
    makeLog('click_retry_purchase', {
      code,
    })
    router.push('/concept-select')
  }

  const concept = useMemo<Concept | null>(() => conceptQuery.data ?? null, [conceptQuery.data])

  const handleScroll = (e: any) => {
    var scrollPosition = e.target.scrollLeft / e.target.scrollWidth
    if (scrollPosition < 0.1) {
      setScrollIndex(0)
    } else if (scrollPosition < 0.3) {
      setScrollIndex(1)
    } else if (scrollPosition < 0.5) {
      setScrollIndex(2)
    } else if (scrollPosition < 0.7) {
      setScrollIndex(3)
    } else {
      setScrollIndex(4)
    }
  }

  const handlePurchase = async () => {
    makeLog('click_purchase', {
      templateGroupId: concept?.id,
      templateName: concept?.name,
      from_page: from_page || 'scarlett_single_concept',
    })

    if (!concept || !concept.id || !concept?.productId || !uuid || !userId) {
      makeLog('purchase_try_without_data', {
        templateGroupId: concept?.id,
        templateName: concept?.name,
        userData: { uuid, userId },
      })

      toast.error('Need to login first.')
      router.push('/?utm_source=single_concept_page_payment_try_without_data_scarlett')
      return
    }

    const order = await createOrder({
      productIds: [Number(concept.productId)],
      uuid: uuid!,
    })

    try {
      await TossPay({
        userId,
        paymentMethod: '카드',
        amount: order.data.amount,
        orderId: order.data.orderId,
        orderName: order.data.orderName,
        successUrl: `${document.location.origin}/payment/bridge/success?conceptIds=${concept.id}&userId=${userId}`,
        failUrl: `${document.location.origin}/payment/bridge/fail?conceptIds=${concept.id}&userId=${userId}`,
        easyPay: '카카오페이',
        flowMode: 'DEFAULT',
      })
    } catch (e) {
      setBottomSheetOpen(true)
      // console.log(e.status)
      Sentry.captureException(e)
      Sentry.addBreadcrumb({
        category: errorLog.purchase,
        message: 'Fail to boot toss pay',
        data: {
          userId,
          paymentMethod: '카드',
          amount: order.data.amount,
          orderId: order.data.orderId,
          orderName: order.data.orderName,
          successUrl: `${document.location.origin}/payment/bridge/success?&conceptIds=${concept.id}&userId=${userId}`,
          failUrl: `${document.location.origin}/payment/bridge/fail?&conceptIds=${concept.id}&userId=${userId}`,
          flowMode: 'DEFAULT',
        },
      })
      Sentry.captureMessage('Fail to boot toss pay')
      setTimeout(() => {
        setDismissable(true)
      }, 1000)
    }
  }

  useEffect(() => {
    if (router.isReady && conceptId && code) {
      if (code != 'PAY_PROCESS_CANCELED') {
        setBottomSheetOpen(true)
        setTimeout(() => {
          setDismissable(true)
        }, 1000)
      } else if (code === 'PAY_PROCESS_CANCELED') {
        makeLog('cancel_purchase', {
          templateGroupId: conceptId,
        })
      }
    }
  }, [router.isReady, code, conceptId])

  useEffect(() => {
    if (router.isReady) {
      if (!userId || !uuid || !countryCode) {
        toast.error('Need to login first.')
        router.push('/?utm_source=single_concept_page_without_data')
        return
      }

      makeLog('view_single_concept_page', {
        templateGroupId: conceptId,
        from_page: from_page || 'unknown',
      })
    }
  }, [router.isReady, conceptId, from_page])

  return (
    <div
      style={{ maxWidth: '500px' }}
      className="relative flex flex-col items-center justify-between w-full h-full mx-auto"
    >
      <Container>
        <>
          <div
            className={classNames([
              'flex',
              'flex-col',
              'text-center',
              'py-6',
              'items-center',
              'justify-center',
            ])}
          >
            <h2 className={classNames(['text-2xl', 'font-light'])}>{concept?.name ?? ''}</h2>
            <strong className={classNames(['text-gray-600', 'font-light', 'text-lg'])}>
              {concept?.description ?? ''}
            </strong>

            {concept && (
              <div className="flex flex-row justify-center mt-6">
                <Button
                  rounded={true}
                  auto={true}
                  style={{
                    width: '160px',
                    height: '42px',
                  }}
                  onPress={() => handlePurchase()}
                >
                  {`${countryMap[countryCode!].currencyFormat?.replace(
                    '{amount}',
                    `${Math.round(concept?.price / 0.06)?.toLocaleString()}`,
                  )} -> ${countryMap[countryCode!].currencyFormat?.replace(
                    '{amount}',
                    `${concept.price?.toLocaleString()}`,
                  )}`}
                </Button>
              </div>
            )}
          </div>

          <div className={classNames(['flex', 'flex-col', 'text-center', 'py-4'])}>
            <div className="flex flex-row justify-center items-center">
              {Array(concept?.images.length)
                .fill(null)
                .map((_, index) => {
                  if (index == scrollIndex) {
                    return (
                      <div
                        key={index}
                        className="flex justify-center items-center h-3 w-3 rounded-full bg-black mx-1"
                      ></div>
                    )
                  } else {
                    return (
                      <div
                        key={index}
                        className="flex justify-center items-center h-1.5 w-1.5 rounded-full bg-white border border-black border-solid mx-1"
                      ></div>
                    )
                  }
                })}
            </div>
          </div>
        </>
      </Container>
      <div
        className="bottom-0 flex overflow-x-scroll snap-x snap-mandatory w-full h-full scrollbar-hide mt-1"
        onScroll={handleScroll}
      >
        {[''].concat(concept?.images ?? []).map((url, index) => {
          if (index == 0) {
            return (
              <div
                key={index}
                className="flex-none w-full snap-center snap-always"
              >
                <div className="flex justify-center">
                  <div
                    className="grid grid-cols-2 grid-rows-2 w-full snap-center"
                    style={{ maxWidth: '500px' }}
                  >
                    {concept?.images.map((url, idx) => {
                      return (
                        <Image
                          src={url}
                          alt="image"
                          className="w-full"
                          key={idx}
                          width={195}
                          height={221}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          }
          return (
            <div
              key={index}
              className="flex-none w-full snap-center snap-always"
            >
              <div
                className="flex justify-center"
                style={{ maxWidth: '500px' }}
              >
                <Image
                  src={url}
                  alt="image"
                  className="w-full"
                  width={390}
                  height={442}
                />
              </div>
            </div>
          )
        })}
        <PurchaseFailBottomsheet
          visible={bottomSheetOpen}
          onDismiss={() => {
            if (dismissable) {
              setBottomSheetOpen(false)
              setDismissable(false)
            }
          }}
          handlePurchase={retryHandler}
        />
      </div>
    </div>
  )
}

export default PackagePage
